<template>
  <div class="index">
    <div class="page-mine">
      <div class="top-part">
        <div class="bg-card">
          <div class="card-item">
            <div class="user-info">
              <div class="company">
                <div class="company-name fs14"></div><!----></div>
              <div class="avatar">
                <div class="user-avatar van-image">
                  <img class="van-image__img">
                  <div class="van-image__loading">
                    <img
                        :src="userDatalt.avatar" alt=""
                        class="user-avatar"></div>
                </div>
              </div>
            </div>
            <div class="detail-wrapper">
              <div style="    color: #fff;
    font-size: 20px;">{{ userDatalt.name }}
              </div>
<!--              <div class="info" style="    font-size: 12px;-->
<!--    color: rgba(255,255,255,.4);-->
<!--    margin-top: 5px;">-->
<!--                <a-icon type="edit"/>-->
<!--                待完善资料-->
<!--              </div>-->
            </div>
          </div>
        </div>
        <div class="event">
<!--          <div class="summary">-->
<!--            <div class="left-label fs16">我的小结</div>-->
<!--            <div class="ranking"><span class="fs16">暂无排名</span>&lt;!&ndash;&ndash;&gt;</div>-->
<!--          </div>-->
          <div class="event-list" v-if="false"><!----><!---->
<!--            <div class="event-entry">-->
<!--              <div class="left-label fs16">意见反馈</div>-->
<!--              <van-icon name="arrow"/>-->
<!--            </div>-->
            <van-divider style="margin: 1px 0"/>
            <div class="event-list" style="padding: 0;"><!----><!---->
              <div class="event-entry" @click="routerPush">
                <div class="left-label fs16">联系我们</div>
                <van-icon name="arrow"/>
              </div>
            </div>
          </div>
          <div class="event-list" style="margin-top: 10px;"><!----><!---->
            <!--            <div class="event-entry">-->
            <!--              <div class="left-label fs16">意见反馈</div>-->
            <!--              <van-icon name="arrow"/>-->
            <!--            </div>-->
            <van-divider style="margin: 1px 0"/>
            <div class="event-list" style="padding: 0;"><!----><!---->
              <div class="event-entry" @click="switchDebug">
                <div class="left-label fs16">调试开关</div>
                <van-switch v-model="debug" size="22px" />
              </div>
            </div>
          </div>
<!--          <div class="event-list" style="margin-top: 16px;">&lt;!&ndash;&ndash;&gt;&lt;!&ndash;&ndash;&gt;-->
<!--            <div class="event-entry">-->
<!--              <div class="left-label fs16">新手引导</div>-->
<!--              <van-icon name="arrow"/>-->
<!--            </div>-->
<!--          </div>-->
        </div>
      </div>
    </div>
    <van-tabbar v-model="active" class="tab">
      <van-tabbar-item replace to="/">
        <span>首页</span>
        <template #icon="props">
          <img v-if="props.active" src="../../assets/images/home.png"/>
          <img v-else src="../../assets/images/home-active.png">
        </template>
      </van-tabbar-item>
      <van-tabbar-item replace to="/contact/index" icon="search">
        <span>客户</span>
        <template #icon="props">
          <img v-if="props.active" src="../../assets/images/customer-active.png"/>
          <img v-else src="../../assets/images/customer.png">
        </template>
      </van-tabbar-item>
      <van-tabbar-item replace to="/tool/index" icon="setting-o">
        <span>运营工具</span>
        <template #icon="props">
          <img v-if="props.active" src="../../assets/images/function-active.png"/>
          <img v-else src="../../assets/images/function.png">
        </template>
      </van-tabbar-item>
      <van-tabbar-item replace to="/my" icon="setting-o">
        <span>我的</span>
        <template #icon="props">
          <img v-if="props.active" src="../../assets/images/my-active.png"/>
          <img v-else src="../../assets/images/my.png">
        </template>
      </van-tabbar-item>
    </van-tabbar>
  </div>
</template>

<script>
import {userCenter} from "../../api/homePage";

export default {
  data() {
    return {
      // 文字
      message: '',
      // tbas切换
      tabsChose: 0,
      // tab 切换
      active:3,
      userDatalt: {
        name: ''
      },
      debug: false
    }
  },
  created() {
    if (localStorage.getItem("debug") === 'true') {
      this.debug = true
    } else {
      this.debug = false
    }
    this.userDetails()
  },
  watch: {},
  methods: {
    routerPush(){
      this.$router.push('/my/contact')
    },
    userDetails() {
      userCenter().then(res => {
        this.userDatalt = res.data.baseInfo
      })
    },
    switchDebug() {
      if (this.debug) {
        localStorage.setItem("debug", "true")
      } else {
        localStorage.removeItem("debug")
      }
      window.location.reload()
    }
  }
}
</script>

<style lang="less" scoped>
/deep/.van-switch__node{
  top: -1px !important;
}
/deep/.van-tabbar{
  box-sizing: content-box !important;
}
.index {
  font-size: 14px;
  height: 100%;
  background: #F1f2f5;

  p {
    margin: 0 !important;
  }

  .fs14 {
    font-size: 14px;
  }

  .fs16 {
    font-size: 16px;
  }

  .page-mine {
    position: relative;
    min-height: 100%;
    background: #f2f3f7;

    .top-part {
      height: 100%;

      .bg-card {
        background: linear-gradient(180deg, #3974c7, #0a42c3 55%, #2b63cd);

        .card-item {
          width: 100%;
          height: 220px;
          background: url(../../assets/images/background-my.png) no-repeat 50%/contain;
          background-size: 100% 100%;
          padding: 36px 34px 0;

          .user-info {
            display: flex;
            justify-content: space-between;

            .company {
              cursor: pointer;
              display: flex;
              flex-direction: row;

              .company-name {
                opacity: .8;
                margin-right: 4px;
                font-weight: 500;
                color: #fff;
                line-height: 20px;
                max-width: 200px;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
              }
            }

            .avatar {
              cursor: pointer;
              margin-top: -4px;

              .user-avatar {
                width: 48px;
                height: 48px;
                border-radius: 8px;
                -o-object-fit: cover;
                object-fit: cover;
                position: relative;
                display: inline-block;

                .van-image__img {
                  display: block;
                  width: 100%;
                  height: 100%;
                  border-radius: 8px;
                }

                .van-image__loading {
                  width: 100%;
                  height: 100%;
                  position: absolute;
                  top: 0;
                  left: 0;
                  display: -webkit-box;
                  display: -webkit-flex;
                  display: flex;
                  -webkit-box-orient: vertical;
                  -webkit-box-direction: normal;
                  -webkit-flex-direction: column;
                  flex-direction: column;
                  -webkit-box-align: center;
                  -webkit-align-items: center;
                  align-items: center;
                  -webkit-box-pack: center;
                  -webkit-justify-content: center;
                  justify-content: center;
                  color: #969799;
                  font-size: 14px;
                  background-color: #f7f8fa;
                  border-radius: 8px;

                  img {
                    width: 48px;
                    height: 48px;
                    border-radius: 8px;
                    -o-object-fit: cover;
                    object-fit: cover;
                  }

                }
              }
            }
          }
        }
      }
    }

    .event {
      height: 500px;
      padding: 20px 12px 0 12px;
      z-index: 1;
      position: relative;
      margin-top: -56px;
      background: #f2f3f7;
      border-radius: 4px 4px 0 0;

      .summary {
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 16px;
        height: 58px;
        background: #fff;
        border-radius: 8px;
        padding: 16px;
      }

      .left-label {

      }

      .ranking {
        display: flex;
        align-items: center;
      }

      .event-list {
        border-radius: 6px;
        background-color: #fff;
        padding: 0 16px;

        .event-entry {
          cursor: pointer;
          display: flex;
          align-items: center;
          justify-content: space-between;
          height: 58px;
        }

        .left-label {
          font-weight: 400;
          color: #000;
          line-height: 26px;
        }
      }
    }
  }

  .footer-new {
    background: rgb(242, 244, 248);
    color: rgb(204, 204, 204);
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    padding: 75px 0;
    line-height: 17px;
    text-align: center;

    .privacy-entry {
      color: #2b60dd;
      opacity: .6;
      margin-left: 15px;
      position: relative;
    }
  }
}
</style>
